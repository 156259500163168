import React from 'react'
import { useLangContext } from '../context/lang.context'
import Layout from '../components/Layout'
import { graphql } from 'gatsby'
import {
  sContainer,
  sTitle,
  sImage,
  sGatsbyImage,
  sHtml,
  colors,
  sContainerPadding,
} from '../style'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Seo from '../components/Seo'
import Button from '../components/Button'
import alt from '../content/alt'
import ImageCredit from '../components/ImageCredit'
import FormNewsletter from '../components/FormNewsletter'

export default function BlogTemplate({ data }) {
  const { lang } = useLangContext()

  const {
    date,
    blog_slug,
    updatedAt,
    first_block_text,
    image,
    keywords,
    seo_desc,
    seo_title,
    title,
  } = data.blog

  const moreArticles = {
    pl: 'Więcej artykułów',
    en: 'More articles'
  }

  return (
    <Layout white>
      <Seo
        title={seo_title}
        desc={seo_desc}
        imageUrl={image.file.url}
        url={'blog/' + blog_slug}
        updatedAt={updatedAt}
        createdAt={date}
        keywords={keywords}
        isArticle
      />
      <section css={[sContainer, sContainerPadding]}>
        <h1 css={[sTitle, { fontSize: '2rem' }]}>{title}</h1>
        <div css={[sImage, { backgroundColor: colors.greyLighter }]}>
          <GatsbyImage
            image={getImage(image)}
            style={sGatsbyImage}
            alt={title + alt}
          />
          <ImageCredit>{image?.description}</ImageCredit>
        </div>
        <article
          css={sHtml}
          dangerouslySetInnerHTML={{
            __html: first_block_text.childMarkdownRemark.html.replaceAll(
              'h1>',
              'h2>'
            ),
          }}></article>
        <Button glow link='/blog/?articles'>
          {moreArticles[lang]}
        </Button>
      </section>
      <FormNewsletter />
    </Layout>
  )
}

export const query = graphql`
  query ($id: String!) {
    blog: contentfulBlog(id: {eq: $id} ) {
      node_locale
      id
      blog_slug
      title
      description
      date
      updatedAt
      seo_title
      seo_desc
      keywords
      first_block_text {
        childMarkdownRemark {
          html
        }
      }
      image {
        description
        gatsbyImageData(quality: 85, placeholder: DOMINANT_COLOR, formats: [AUTO, WEBP])
        file {
          url
          fileName
        }
      }
    }
  }
`
