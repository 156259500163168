import React from 'react'
import { colors, s } from '../style'

const Section = ({
  children,
  top,
  paddingTop,
  paddingBottom,
  bottom,
  mobile,
  bottomSmall,
  bottomXS,
  topXS,
  relative,
  id,
  pbXL,
}) => (
  <section
    id={id}
    css={[
      sSectionSides,
      top && sMarginTop,
      paddingTop && sPaddingTop,
      bottom && sMarginBottom,
      bottomSmall && sMarginBottomSmall,
      bottomXS && sMarginBottomXS,
      topXS && sMarginTopXS,
      mobile && sSectionSidesMobile,
      relative && sRelative,
      paddingBottom && sPaddingBottom,
      pbXL && sPaddingBottomXL,
    ]}>
    {children}
  </section>
)

export const SectionBackground = ({ children, gradient, color, ...props }) => {
  return (
    <Section {...props}>
      <div
        css={[
          sPadding,
          sBorderRadius,
          { background: color || gradient || colors.gradientLight },
        ]}>
        {children}
      </div>
    </Section>
  )
}

const sMarginTop = {
  marginTop: '4rem',
  [s.md]: { marginTop: '6rem' },
  [s.lg]: { marginTop: '8rem' },
}

const sPaddingTop = {
  paddingTop: '4rem',
  [s.md]: { paddingTop: '6rem' },
  [s.lg]: { paddingTop: '8rem' },
}

const sMarginBottom = {
  marginBottom: '4rem',
  [s.md]: { marginBottom: '8rem' },
  [s.lg]: { marginBottom: '16rem' },
}

const sMarginBottomSmall = {
  marginBottom: '4rem',
  [s.md]: { marginBottom: '4rem' },
  [s.lg]: { marginBottom: '8rem' },
}

const sPaddingBottom = {
  paddingBottom: '2rem',
  [s.md]: { paddingBottom: '4rem' },
}

const sPaddingBottomXL = {
  paddingBottom: '4rem',
  [s.xs]: { paddingBottom: '4rem' },
  [s.md]: { paddingBottom: '6rem' },
  [s.lg]: { paddingBottom: '8rem' },
}

const sMarginBottomXS = {
  marginBottom: '2rem',
  [s.md]: { marginBottom: '4rem' },
}

const sMarginTopXS = {
  marginTop: '2rem',
  [s.md]: { marginTop: '4rem' },
}

const sSectionSides = {
  width: '100vw',
  maxWidth: 1500,
  margin: 'auto',
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'wrap',
  padding: '0 2rem',
  [s.md]: {
    padding: '0 4rem',
  },
}

const sSectionSidesMobile = {
  [s.xs]: { padding: '0 1rem' },
}

const sRelative = {
  position: 'relative',
  top: 0,
  left: 0,
}

const sPadding = {
  padding: '2rem',
  [s.md]: {
    padding: '4rem',
  },
}

const sBorderRadius = {
  borderRadius: '1rem',
  [s.md]: {
    borderRadius: '2rem',
  },
}

export default Section
