import React from 'react'

const ImageCredit = ({ children }) => {
  if (!children) return null
  return <span css={sImageCredits}>{children}</span>
}

const sImageCredits = {
  position: 'absolute',
  fontStyle: 'italic',
  bottom: -24,
  right: 32,
  fontSize: 10,
  opacity: 0.3,
}

export default ImageCredit
